import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { CSSObject, Global } from '@emotion/react';
import { createAppTheme } from '@arwes/theme';
import { createAppStylesBaseline } from '@arwes/core';
import { Animator } from '@arwes/react-animator';
import { Dots, GridLines, MovingLines } from '@arwes/react-bgs';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const theme = createAppTheme();
const stylesBaseline = createAppStylesBaseline(theme);

// backgroundColor: '#000906',
//           backgroundImage: 'radial-gradient(85% 85% at 50% 50%, hsla(185, 100%, 25%, 0.25) 0%, hsla(185, 100%, 25%, 0.12) 50%, hsla(185, 100%, 25%, 0) 100%)'

root.render(
  // <React.StrictMode>
    <>
    
    <Global styles={stylesBaseline as Record<string, CSSObject>} />
    
      <Animator combine  manager='stagger' duration={{ interval: 10 }}>
        <div style={{
          position: 'absolute',
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          
        }}>
          <GridLines
            lineColor='hsla(180, 100%, 75%, 0.05)'
            distance={30}
          />
          <Dots
            color='hsla(180, 100%, 75%, 0.05)'
            distance={30}
          />
          <MovingLines
            lineColor='hsla(180, 100%, 75%, 0.07)'
            distance={30}
            sets={20}
          />
        <App />

        </div>
      

      </Animator>
    </>
    // <App />
    
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
