// import {
//   useBleeps,
//   BleepsOnAnimator,
//   Animated,
//   FrameSVGCorners,
//   Text,
//   aa,
//   aaVisibility
// } from '@arwes/react';

import { Animated, aa, aaVisibility } from "@arwes/react-animated";
import { Animator } from "@arwes/react-animator";
import { useBleeps } from "@arwes/react-bleeps";
import { FrameSVGCorners } from "@arwes/react-frames";
import { ReactElement } from "react";
import { Text } from '@arwes/react-text';
import { createAppTheme } from "@arwes/theme";
import { BleepsOnAnimator } from '@arwes/react-core';
import {bleepsManager, intro} from "./shared/audio"
import { useMediaQuery } from "react-responsive";

const theme = createAppTheme();


const Card = (): ReactElement => {
  const bleeps = useBleeps();
  const hello = false

  const isDesktopOrLaptop = useMediaQuery({ minWidth: 1224 })
  const isBigScreen = useMediaQuery({ minWidth: 1824 })
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 })
  const isPortrait = useMediaQuery({ orientation: 'portrait' })
  // const isRetina = useMediaQuery({ minResolution: '2dppx' })

// hello? '20vw': "100vw"
  return (
    <Animator merge combine manager='stagger'>
      {/* Play the intro bleep when card appears. */}
      <BleepsOnAnimator
        transitions={{ entering: 'intro' }}
        continuous
      />

      <Animated
        className='card'
        style={{
          top:"35%",
          position: 'relative',
          display: 'block',
          maxWidth: isPortrait? "99vw" : isTabletOrMobile? "80vw" : isBigScreen? "30vw": "20vw",
          margin: theme.space([4, 'auto']),
          padding: theme.space(8),
          textAlign: 'center'
        }}
        // Effects for entering and exiting animation transitions.
        animated={[aaVisibility(), aa('y', '2rem', 0)]}
        // Play bleep when the card is clicked.
        onClick={() => bleeps.click?.play()}
      >
        {/* Frame decoration and shape colors defined by CSS. */}
        <style>{`
          .card .arwes-react-frames-framesvg [data-name=bg] {
            color: ${theme.colors.primary.deco(1)};
          }
          .card .arwes-react-frames-framesvg [data-name=line] {
            color: ${theme.colors.primary.main(4)};
          }
        `}</style>

        <Animator>
          <FrameSVGCorners strokeWidth={2} />
        </Animator>

        <Animator>
          <Text as='h1'>
            Under Construction
          </Text>
        </Animator>

        <Animator>
          <Text>
            The owner is busy with side projects. 🙁 <br /> Stay tuned.
            {/* This Site will be live soon. <br /> Stay tuned */}
          </Text>
        </Animator>
      </Animated>
    </Animator>
  );
};


export default Card